@import "../themes";

@mixin generate-scroll($themes: $themes) {
  @each $theme, $map in $themes {
    .#{$theme}-theme {
      $scrollbar: map-get($map, scrollbar);

      /* width */
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 8px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background-color: #b5b5b5 !important;
        border-radius: 8px;
      }
     

      // ::-webkit-scrollbar-thumb:hover {
      //   background-color: map-get($scrollbar, tumb-background-hover);
      // }

      &::-webkit-scrollbar-thumb {
        background-color: #b5b5b5 !important;
        border-radius: 8px;
      }

      // scrollbar-color: map-get($scrollbar, tumb-background) transparent;

      // ::-webkit-scrollbar-corner {
      //   background-color: transparent
      // }

    }
  }
}