// NOTIFIER: WARNING TYPE STYLES

$notifier-warning-background-color: white !default;
$notifier-warning-font-color: #042315 !default;
$notifier-warning-icon-color: #042315 !default;

.notifier__notification--warning {
	background-color: $notifier-warning-background-color;
	color: $notifier-warning-font-color;

	.notifier__notification-button-icon { // 16x16 fixed size
		fill: $notifier-warning-icon-color;
	}

}
