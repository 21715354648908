// NOTIFIER: INFO TYPE STYLES

$notifier-info-background-color: white !default;
$notifier-info-font-color: #042315 !default;
$notifier-info-icon-color: #042315 !default;

.notifier__notification--info {
	background-color: $notifier-info-background-color;
	color: $notifier-info-font-color;

	.notifier__notification-button-icon { // 16x16 fixed size
		fill: $notifier-info-icon-color;
	}

}
