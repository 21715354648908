// NOTIFIER: SUCCESS TYPE STYLES

$notifier-success-background-color: white !default;
$notifier-success-font-color: #042315 !default;
$notifier-success-icon-color: #042315 !default;

.notifier__notification--success {
	background-color: $notifier-success-background-color;
	color: $notifier-success-font-color;
	font-size: 14px;

	.notifier__notification-button-icon { // 16x16 fixed size
		fill: $notifier-success-icon-color;
	}

}
