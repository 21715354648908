@import "variables";

$levels-list: error warn info success;

.notifier__notification {
  padding-left: 15px;
  margin-top: 10px;
  color: $blue-200;
  //background-color:  $blue-500;
  min-height: 50px;
  box-shadow: 0px 0px 10px #0000001F;

  .notifier__notification-button {
    position: absolute;
    right: 5px;
    top: -5px;
    fill: $blue-300;

    .notifier__notification-button-icon {
      height: 15px;
    }
  }

  &:before {
    content: '';
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: 2px;
    margin-top: 6px;
  }

  @each $level in $levels-list {
    &.notifier__notification--#{$level} {
      border-left: solid 4px map-get($level-colors, $level);

      &:before {
        background-image: url("../../../assets/icons/#{$level}.svg");
      }
      &has-actions {
        &:before {
          margin-top: 2px;
          align-self: flex-start;
        }
      }
    }
  }

  .notifier__notification-message {
    width: 400px;
    font-size: 12px;
    line-height: 1.73;
    padding-left: 25px;
    padding-right: 10px;
  }
}
